// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD91zxg5wLK4pn0dxnXSMUd0oT0JWG-rDE",
  authDomain: "x-wallet-fcb49.firebaseapp.com",
  projectId: "x-wallet-fcb49",
  storageBucket: "x-wallet-fcb49.appspot.com",
  messagingSenderId: "697233952797",
  appId: "1:697233952797:web:d109e8656fbd67992336f4",
  measurementId: "G-GG46578RLH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
export const db = getFirestore(app);

export const getBaseConfig = async () => {
  const baseRef = doc(db, "base", "config");
  const baseSnap = await getDoc(baseRef);
  if(baseSnap.exists()) {
    return baseSnap.data();
}
};

export default app;