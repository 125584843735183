import usdt from '@assets/crypto/usdt.svg';
import dollar from '@assets/icons/dollar.svg';

const CURRENCIES = [
    {value: 'usd', label: 'USD', icon: dollar},
    // {value: 'usd', label: 'USD', icon: dollar},
    // {value: 'eur', label: 'EUR', icon: euro},
    // {value: 'gbp', label: 'GBP', icon: pound},
    // {value: 'aud', label: 'AUD', icon: dollar},
    // {value: 'cad', label: 'CAD', icon: dollar},
]

const CRYPTO_CURRENCIES = [
    {
        value: 'usdt',
        label: 'USDT',
        name: 'Tether',
        icon: usdt,
        usd: 1.00,
        exchange: {
            usd: 1.00,
        },
        change: 5.09,
        isFavorite: false,
        isTrending: true
    },
]

const MERCHANTS = [
    {value: 'банк', label: 'Хаан банк'},
    // {value: 'mastercard', label: 'Mastercard USD'},
    // {value: 'paypal', label: 'Paypal'},
    // {value: 'apple', label: 'Apple Pay'},
    // {value: 'google', label: 'Google Pay'},
]

const DEPOSIT_OPTIONS = [
    {value: 'usdtbsc', label: 'USDT (BEP20)'},
]


export {CURRENCIES, CRYPTO_CURRENCIES, MERCHANTS, DEPOSIT_OPTIONS }