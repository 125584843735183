// GA
import ReactGA from 'react-ga4';

// utils
import {lazy, Suspense, useState} from 'react';

// styles
import ThemeStyles from '@styles/theme';
import './style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-grid-layout/css/styles.css';

// contexts
import {SidebarProvider} from '@contexts/sidebarContext';
import {ThemeProvider} from 'styled-components';
import {ModalProvider} from '@contexts/modalContext';

// hooks
import {useThemeProvider} from '@contexts/themeContext';
import {useEffect, useRef} from 'react';
import {useWindowSize} from 'react-use';

// components
import {Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Loader from '@components/Loader';
import Sidebar from '@components/Sidebar';
import BottomNav from '@components/BottomNav';
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';

const Dashboard = lazy(() => import('@pages/Dashboard'));
const Trade = lazy(() => import('@pages/Trade'));
const Actions = lazy(() => import('@pages/Actions'));
const Wallet = lazy(() => import('@pages/Wallet'));
const NFT = lazy(() => import ('@pages/NFT'));
const Collections = lazy(() => import('@pages/Collections'));
const SignIn = lazy(() => import('@pages/SignIn'));
const SignUp = lazy(() => import('@pages/SignUp'));
const Verification = lazy(() => import('@pages/Verification'));


const App = () => {
    const appRef = useRef(null);
    const {theme} = useThemeProvider();
    const {width} = useWindowSize();
    const token = localStorage.getItem('token');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        appRef.current && appRef.current.scrollTo(0, 0);
    }, []);

    const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
    gaKey && ReactGA.initialize(gaKey);

    onAuthStateChanged(auth, (user) => {
        setIsAuthenticated(!!user);
    });


    return (
        <SidebarProvider>
            <ThemeProvider theme={{theme: theme}}>
                <ThemeStyles/>
                <ToastContainer theme="colored" autoClose={2000} toastStyle={{borderRadius: 4}}/>
                <ModalProvider>
                    <div ref={appRef}>
                        {width < 768 && isAuthenticated ? <BottomNav/> : <Sidebar/>}
                        <Suspense fallback={<Loader visible/>}>
                            <Routes>
                                { isAuthenticated ? <>
                                    <Route path="/" element={<Dashboard/>}/>
                                    <Route path="/trade" element={<Trade/>}/>
                                    <Route path="/actions" element={<Actions/>}/>
                                    <Route path="/nft" element={<NFT/>}/>
                                    <Route path="/collections" element={<Collections/>}/>
                                    </>
                                    : <>
                                    <Route path="/" element={<SignIn/>}/> 
                                    <Route path="/sign-in" element={<SignIn/>}/>
                                    <Route path="/sign-up" element={<SignUp/>}/>
                                    </>}
                                
                                {/* <Route path="/wallet" element={<Wallet/>}/> */}
                                {/* {/* <Route path="/verification" element={<Verification/>}/> */}
                            </Routes>
                        </Suspense>
                    </div>
                </ModalProvider>
            </ThemeProvider>
        </SidebarProvider>
    );
}

export default App;
