import dayjs from 'dayjs';

const notifications = [
    // {
    //     id: 'notification-1',
    //     title: 'Зарлага амжилттай',
    //     text: `Таны 7$-н зарлага амжилттай хийгдлээ.`,
    //     read: true,
    //     date: dayjs().subtract(2, 'month'),
    // },
    // {
    //     id: 'notification-2',
    //     title: 'Орлого баталгаажлаа',
    //     text: `Таны 5$-н орлого таны дансанд орлоо`,
    //     read: false,
    //     date: dayjs().subtract(2, 'day'),
    // },
    // {
    //     id: 'notification-3',
    //     title: 'Зарлага амжилттай',
    //     text: `Таны 95$-н зарлага амжилттай хийгдлээ.`,
    //     read: true,
    //     date: dayjs().subtract(3, 'day'),
    // },
    // {
    //     id: 'notification-4',
    //     title: 'Орлого баталгаажлаа',
    //     text: `Таны 5$-н орлого таны дансанд орлоо`,
    //     read: true,
    //     date: dayjs().subtract(3, 'day').subtract(1, 'hour'),
    // },
    // {
    //     id: 'notification-5',
    //     title: 'Орлого баталгаажлаа',
    //     text: `Таны 5$-н орлого таны дансанд орлоо`,
    //     read: true,
    //     date: dayjs().subtract(7, 'day').subtract(2, 'hour'),
    // },
];

export default notifications